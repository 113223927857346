import { Card, CardContent, Grid } from "@mui/material";
import oicLogo from "../../assets/images/oicLogo.svg";
import uiiLogo from "../../assets/images/uiiLogo.svg";
import nicLogo from "../../assets/images/nicLogo.svg";
import niaLogo from "../../assets/images/NiaLogo.svg";
import licLogo from "../../assets/images/licLogo.svg";

import SingleCardContents from "./singleCardColumnContent";
// import { CardHeader, Divider } from "@material-ui/core";
import { CardHeader, Divider } from "@mui/material";
import {
  StyledCardTitle,
  StyledLink,
} from "../../StyledComponents/StyledComponents";
const ThirdRowCards = (props) => {
  return (
    <Grid
      item
      sx={{
        // pl: "25px",
        // pr: "25px",
        justifyContent: "center",
        pt: "30px",
      }}
    >
      <Grid item>
        <Card
          variant="outlined"
          sx={{
            borderRadius: "10px",
          }}
        >
          <CardHeader
            sx={{ display: "flex", justifyContent: "space-between" }}
            title={
              <Grid display="flex" justifyContent="space-between">
                <StyledCardTitle>
                  Users Calculations Per Company
                </StyledCardTitle>
                <StyledLink href="calcDetails" underline="none" color="inherit">
                  View
                </StyledLink>
              </Grid>
            }
          />
          <Divider />
          <CardContent
            sx={{
              borderRadius: "10px",
            }}
          >
            <Grid container spacing={1.5} justifyContent="center">
              <SingleCardContents
                chartLimitValue={props.uiiChartLimitValue}
                chartPercentage={props.uiiChartLimitValue}
                donutColor={"#FF69B4"}
                cardIcon={uiiLogo}
                count={props.totalUsersUII}
                title={"UII"}
              />
              <SingleCardContents
                chartLimitValue={props.niaChartLimitValue}
                chartPercentage={props.niaChartLimitValue}
                donutColor={"#31E4A8"}
                cardIcon={niaLogo}
                count={props.totalUsersNIA}
                title={"NIA"}
              />
              <SingleCardContents
                chartLimitValue={props.nicChartLimitValue}
                chartPercentage={props.nicChartLimitValue}
                donutColor={"#615CFF"}
                cardIcon={nicLogo}
                count={props.totalUsersNIC}
                title={"NIC"}
              />
              <SingleCardContents
                chartLimitValue={props.oicChartLimitValue}
                chartPercentage={props.oicChartLimitValue}
                donutColor={"#BE7CFF"}
                cardIcon={oicLogo}
                count={props.totalUsersOIC}
                title={"OIC"}
              />
              <SingleCardContents
                chartLimitValue={props.licChartLimitValue}
                chartPercentage={props.licChartLimitValue}
                donutColor={"#43D2FF"}
                cardIcon={licLogo}
                count={props.totalUsersLIC}
                title={"LIC"}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export default ThirdRowCards;
