import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import RefreshIcon from "@mui/icons-material/Refresh";

const DateRangeSelector = (props) => {
  const {
    startDate,
    setStartDate,
    setEndDate,
    endDate,
    handleEndDateChange,
    handleStartDateChange,
  } = props;

  const [startRefreshKey, setStartRefreshKey] = useState(0);
  const [endRefreshKey, setEndRefreshKey] = useState(0);

  const handleEndRefreshClick = () => {
    setStartDate(null);
    setEndDate(null);
    setStartRefreshKey((prevKey) => prevKey + 1);
    setEndRefreshKey((prevKey) => prevKey + 1);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: "0.7rem",
        alignItems: "center",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            text: "center",
          }}
          key={startRefreshKey}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ backgroundColor: "#F8F8F8" }}
              label="Start Date"
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              autoComplete="off"
              format="DD-MM-YYYY"
            />
          </LocalizationProvider>
        </div>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
          key={endRefreshKey}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                backgroundColor: "#F8F8F8",
              }}
              label="End Date"
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              autoComplete="off"
              format="DD-MM-YYYY"
            />
          </LocalizationProvider>
          <div>
            {endDate !== null ? (
              <div onClick={handleEndRefreshClick}>
                <RefreshIcon sx={{ color: "#A6A4A4" }} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRangeSelector;
