import React from "react";
import { Box, Card, Dialog, Divider, Grid, Typography } from "@mui/material";
import PlanName from "../assets/images/planName.svg";
import Startdate from "../assets/images/startDate.svg";
import EndDate from "../assets/images/endDate.svg";
import PlanStatus from "../assets/images/planStatt.svg";
import Planname from "../assets/images/payRupee.svg";
import PaymentStatus from "../assets/images/payStatus.svg";
import SilverPlan from "../assets/images/Silver.svg";
import GoldPlan from "../assets/images/GoldImage.svg";
import PlatinumPlan from "../assets/images/PlatinumImage.svg";
import TrialPlan from "../assets/images/Trial.svg";
import planActiveImage from "../assets/images/planActiveImage.svg";
import planExpiredImage from "../assets/images/planStatus.svg";

function LatestPlan({ userData }) {
  const styles = {
    borderStyle: "solid 1px",
    borderColor: "#F5F5F5",
    borderBottomWidth: "thin",
  }; //border

  const images = {
    width: "26px",
    height: "26px",
  }; //Avatar

  const text = {
    display: "flex",
    alignItems: "center",
    padding: "12px ",
  }; //header

  const label = {
    color: "#565656",
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "18px",
    letterSpacing: "0.1px",
    margin: "0 12px",
    width: "150px",
  }; //headertext

  const json = {
    color: "#000000",
    fontFamily: "Open Sans",
    fontSize: "11px",
    fontWeight: 500,
    lineHeight: "12px",
    letterSpacing: "0.1px",
  }; //values

  const plans = {
    fontFamily: "Open Sans",
    fontWeight: 600,
    textTransform: "capitalize",
    color: "#565656",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "0.1px",
    padding: "12px",
  };
  let imageSource;

  switch (userData?.LastPlanName) {
    case "Silver":
      imageSource = SilverPlan;
      break;
    case "Gold":
      imageSource = GoldPlan;
      break;
    case "Platinum":
      imageSource = PlatinumPlan;
      break;
    case "nill":
      imageSource = TrialPlan;
      break;
    case "Trial":
      imageSource = TrialPlan;
      break;
    default:
      imageSource = null;
      break;
  }
  return !userData ? null : (
    <Box
      sx={{
        border: "solid 1px",
        borderColor: "#ECEBEB",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        // padding: '18px',
        width: "320px",
        height: "350px",
        //box
      }}
    >
      <Box>
        <Grid container sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={plans}>Latest Plan Details</Typography>
          <Divider style={styles} />

          <Grid style={text}>
            <img style={images} src={PlanName} alt="Profile" />
            <Typography style={label}> Plan Name </Typography>
            <Typography sx={json}>
              {" "}
              {imageSource && <img src={imageSource} alt="Image" />}
            </Typography>
          </Grid>
          <Divider style={styles} />

          <Grid style={text}>
            <img style={images} src={Startdate} alt="Mail" />
            <Typography style={label}> Start Date </Typography>
            <Typography sx={json}>{userData?.LastPlanStartDate}</Typography>
          </Grid>
          <Divider style={styles} />

          <Grid style={text}>
            <img style={images} src={EndDate} alt="Phone" />
            <Typography style={label}> End Date </Typography>
            <Typography sx={json}> {userData?.LastPlanEndDate} </Typography>
          </Grid>
          <Divider style={styles} />

          <Grid style={text}>
            <img style={images} src={PlanStatus} alt="DOB" />
            <Typography style={label}> Plan Status </Typography>
            <Typography sx={json}>
              <img
                src={
                  userData?.LastPlanStatus === "Active"
                    ? planActiveImage
                    : planExpiredImage
                }
                alt="Image"
              />
            </Typography>
          </Grid>
          <Divider style={styles} />

          <Grid style={text}>
            <img style={images} src={Planname} alt="Signature" />
            <Typography style={label}> Amount Paid </Typography>
            <Typography sx={json}> {userData?.LastPlanAmount} </Typography>
          </Grid>
          <Divider style={styles} />

          <Grid style={text}>
            <img style={images} src={PaymentStatus} alt="Address" />
            <Typography style={label}> Payment Status </Typography>
            <Typography sx={{ ...json, color: "green" }}> Success </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default LatestPlan;
