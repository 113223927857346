import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { AppContext } from "../Context/AppContext";
import { useContext } from "react";
export default function SelectLabels() {
  const { limit, setLimit } = useContext(AppContext);

  const handleChange = (event) => {
    setLimit(event.target.value);
  };
  return (
    <FormControl
      fullWidth
      sx={{
        bgcolor: "#F8F8F8",
      }}
    >
      <Select
        value={limit}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        sx={{ color: "black" }}
      >
        <MenuItem value="">10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={30}>30</MenuItem>
      </Select>
    </FormControl>
  );
}
