import axios from "axios";
// let serializedObject = sessionStorage.getItem("userDetails");
// const userDetails = JSON.parse(serializedObject);
const configBaseUrl = process.env.REACT_APP_API_DOMAIN;
export const loginApi = async (credentials) => {
  try {
    const apiData = await axios.post(
      `${configBaseUrl}/user/login`,
      credentials
    );
    if (apiData?.data?.data !== undefined) {
      const serializedObject = JSON.stringify(apiData?.data?.data);
      sessionStorage.setItem("userDetails", serializedObject);
    }

    return apiData;
  } catch (error) {
    console.log(error);
  }
};
export const sendOtpApi = async (credentials) => {
  try {
    const apiData = await axios.post(`${configBaseUrl}/mail/otp`, credentials);
    return apiData;
  } catch (error) {
    console.log(error);
  }
};

export const verifyOtpApi = async (credentials) => {
  try {
    const email = sessionStorage.getItem("Email");

    const requestBody = {
      email: email,
      otp: credentials,
    };

    const apiData = await axios.post(
      `${configBaseUrl}/mail/verify`,
      requestBody
    );
    return apiData;
  } catch (error) {
    console.log(error);
  }
};

export const passwordChangeApi = async (credentials) => {
  const email = sessionStorage.getItem("Email");
  let requestBody = {
    email: email,
    oldPassword: credentials.oldPassword,
    newPassword: credentials.newPassword,
    confirmPassword: credentials.confirmPassword,
  };
  const apiData = await axios.post(
    `${configBaseUrl}/mail/passwordChange`,
    requestBody
  );
  return apiData;
};

export const dashboartCountApi = async () => {
  try {
    let serializedObject = sessionStorage.getItem("userDetails");
    const userDetails = JSON?.parse(serializedObject);
    let token = userDetails?.token?.accessToken;
    const apiData = await axios.get(`${configBaseUrl}/user/activeCount`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return apiData.data;
  } catch (error) {
    console.log(error);
  }
};

export const planDetailsApi = async (
  skip,
  limit,
  searchedData,
  isForSheet,
  startDate,
  endDate
) => {
  startDate = startDate === null ? "" : startDate;
  endDate = endDate === null ? "" : endDate;

  let serializedObject = sessionStorage.getItem("userDetails");
  const userDetails = JSON.parse(serializedObject);
  let token = userDetails?.token?.accessToken;
  if (isForSheet == "true") {
    const response = await axios.get(`${configBaseUrl}/user/plans`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        skip: skip,
        limit: limit,
        searchedData: searchedData,
        isForSheet: isForSheet,
        fromDate: startDate,
        toDate: endDate,
      },
      responseType: "blob",
    });
    const downloadUrl = window.URL.createObjectURL(new Blob([response?.data]));
    const link = document?.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "Plan_Details.xlsx");
    document?.body?.appendChild(link);
    link.click();
    document?.body?.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
  } else {
    const apiData = await axios.get(`${configBaseUrl}/user/plans`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        skip: skip,
        limit: limit,
        searchedData: searchedData,
        isForSheet: isForSheet,
        fromDate: startDate,
        toDate: endDate,
      },
    });

    return apiData?.data;
  }
};

export const calcDetailsApi = async (
  skip,
  limit,
  field,
  query,
  isForSheet,
  startDate,
  endDate
) => {
  startDate = startDate === null ? "" : startDate;
  endDate = endDate === null ? "" : endDate;

  let serializedObject = sessionStorage.getItem("userDetails");
  const userDetails = JSON.parse(serializedObject);
  let token = userDetails?.token?.accessToken;
  if (isForSheet == "true") {
    const response = await axios.get(`${configBaseUrl}/api/calculation`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },

      params: {
        skip: skip,
        limit: limit,
        lob: field,
        searchedData: query,
        isForSheet: isForSheet,
        fromDate: startDate,
        toDate: endDate,
      },
      responseType: "blob",
    });
    const downloadUrl = window.URL.createObjectURL(new Blob([response?.data]));
    const link = document?.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", `${field}_Calculation_Details.xlsx`);
    document?.body?.appendChild(link);
    link.click();
    document?.body?.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
  } else {
    const apiData = await axios.get(`${configBaseUrl}/api/calculation`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        skip: skip,
        limit: limit,
        lob: field,
        searchedData: query,
        isForSheet: isForSheet,
        fromDate: startDate,
        toDate: endDate,
      },
    });
    // console.log(searchedData, "serachedData");
    return apiData?.data;
  }
};

export const userProfileApi = async (userId) => {
  let serializedObject = sessionStorage.getItem("userDetails");
  const userDetails = JSON.parse(serializedObject);
  let token = userDetails?.token?.accessToken;

  const apiData = await axios.get(`${configBaseUrl}/user/userProfile`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      userId: userId,
    },
  });

  return apiData?.data?.data;
};

export const updateUserProfileApi = async (userId, data) => {
  try {
    let serializedObject = sessionStorage.getItem("userDetails");
    const userDetails = JSON.parse(serializedObject);
    let token = userDetails?.token?.accessToken;

    const apiData = await axios.put(`${configBaseUrl}/user/update`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        userId: userId,
      },
    });
    // console.log(userId, "fzxgzxgzgjizdi");
    return apiData?.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addPlanProfileApi = async (userId, data) => {
  try {
    let serializedObject = sessionStorage.getItem("userDetails");
    const userDetails = JSON.parse(serializedObject);
    let token = userDetails?.token?.accessToken;

    const apiData = await axios.post(`${configBaseUrl}/user/addPlan`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        userId: userId,
      },
    });

    return apiData.data;
  } catch (error) {
    return error.response;
  }
};

export const deleteSignatureApi = async (userId, payload) => {
  try {
    let serializedObject = sessionStorage.getItem("userDetails");
    const userDetails = JSON.parse(serializedObject);
    let token = userDetails?.token?.accessToken;

    const apiData = await axios.delete(
      `${configBaseUrl}/user/deleteSignature`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: payload,
        params: {
          userId: userId,
        },
      }
    );

    return apiData.data;
  } catch (error) {
    return error.response;
  }
};
