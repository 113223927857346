import React, { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import {
  Box,
  Dialog,
  Divider,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

function AddPlanDialog({
  openDialog,
  onClose,
  selectPlan,
  setSelectPlan,
  selectDate,
  setSelectDate,
  planAdd,
}) {
  const handlePlanChange = (event) => {
    setSelectPlan(event.target.value);
  };

  const handleDateChange = (date) => {
    const formattedDate = date ? dayjs(date).format("YYYY-MM-DD") : null;
    setSelectDate(formattedDate);
    setErrorMessage("");
  };

  const startOf2021 = dayjs("2021-01-01");

  const [errorMessage, setErrorMessage] = useState("");

  const handleAddClick = () => {
    if (selectPlan && selectDate) {
      planAdd(selectDate);
      onClose();
    } else {
      setErrorMessage("Please Choose Start Date is Required! ");
    }
  };

  let header = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#565656",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    padding: "6px 12px 6px 12px",
  };

  const styles = {
    borderStyle: "solid 1.5px",
    borderColor: "#ECEBEB",
    width: "auto",
  };
  const headertext = {
    color: "#464646",
    fontFamily: "Open Sans",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    padding: "12px",
  };

  const icon = {
    fontSize: "medium",
  };

  const datePickerStyle = {
    width: "300px",
    fontSize: "4px",
    padding: "0px 12px ",
    ".MuiInputBase-root": {
      height: "44px",
    },
    ".MuiSvgIcon-root": {
      width: "20px",
      height: "20px",
    },
  };

  const addbutton = {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#C679F6",
    color: "white",
    textTransform: "capitalize",
    padding: "6px 12px",
    margin: "16px",
    width: "300px",
  };

  return (
    <>
      <Dialog open={openDialog}>
        <Box
          sx={{
            width: "327px",
            height: "auto",
            backgroundColor: "white",
            borderRadius: "12px",
          }}
        >
          <Typography style={header}>
            Add Plan
            <IconButton onClick={onClose}>
              <ClearIcon style={icon} />
            </IconButton>
          </Typography>
          <Divider style={styles} />

          <Typography sx={headertext}> Plan Name </Typography>
          <FormControl
            sx={{
              padding: "2px 12px",
              width: "300px",
            }}
          >
            <Select size="small" value={selectPlan} onChange={handlePlanChange}>
              <MenuItem value="Trial">Trial</MenuItem>
              <MenuItem value="Platinum">Platinum</MenuItem>
              <MenuItem value="Gold">Gold</MenuItem>
              <MenuItem value="Silver">Silver</MenuItem>
            </Select>
          </FormControl>

          <Typography sx={headertext}> Start Date </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              minDate={startOf2021}
              sx={{
                ...datePickerStyle,
              }}
              format="DD-MM-YYYY"
              value={
                selectDate ? dayjs(selectDate, { format: "YYYY-MM-DD" }) : null
              }
              onChange={(date) => handleDateChange(date)}
            />
          </LocalizationProvider>
          {errorMessage && (
            <Typography
              style={{
                color: "red",
                fontFamily: "Open-Sans",

                fontStyle: "normal",
                fontWeight: "400",
                padding: "12px 0px 0px 20px",
                fontSize: "14px",
              }}
            >
              {errorMessage}
            </Typography>
          )}

          <Button sx={addbutton} onClick={handleAddClick} variant="contained">
            Add
          </Button>
        </Box>
      </Dialog>
    </>
  );
}

export default AddPlanDialog;
