import React, { useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  Paper,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Popper from "@mui/material/Popper";
import Uii from "../assets/images/Uii.svg";
import Nia from "../assets/images/Nia.svg";
import Nic from "../assets/images/Nic.svg";
import Oic from "../assets/images/Oic.svg";
import Scooter from "../assets/images/Scooter.svg";
import PrivateCar from "../assets/images/PrivateCar.svg";
import PCVAuto from "../assets/images/PCVAuto.svg";
import PCVTaxi from "../assets/images/PCVTaxi.svg";
import PCVBusMixi from "../assets/images/PCVbusMixi.svg";
import GCVAuto from "../assets/images/GCVAuto.svg";
import GCVTruck from "../assets/images/GCVTruck.svg";
import MiscVehicles from "../assets/images/MiscVehicles.svg";
import CV2Wheeler from "../assets/images/CV-2Wheeler.svg";
import E2Wheeler from "../assets/images/E-2Wheeler.svg";
import EPrivateCar from "../assets/images/E-PrivateCar.svg";
import EPCVAuto from "../assets/images/E-PCVAuto.svg";
import EPCVTaxi from "../assets/images/E-PCVTaxi.svg";
import EPCVBusMixi from "../assets/images/E-PVCbusMixi.svg";
import EGCVAuto from "../assets/images/E-GCVAuto.svg";
import EGCVTruck from "../assets/images/E-GCVTruck.svg";
import ECV2Wheeler from "../assets/images/E-CV-2Wheeler.svg";
import HPrivatecar from "../assets/images/H-PrivateCar.svg";
import HPCVTaxi from "../assets/images/H-PCVTaxi.svg";
import QPrivateCar from "../assets/images/Q-PrivateCar.svg";
import QEPrivateCar from "../assets/images/Q-EPVTCar.svg";

import { DataGrid, gridClasses } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { StyledPaginationItem } from "../StyledComponents/StyledComponents";
export default function StickyMotorTable(props) {
  const {
    planCount,
    resultLimit,
    planData,
    page,
    field,
    handlePageChange,
    isLoading,
    setPage,
    // CustomToolbar,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [popperContent, setPopperContent] = useState(null);

  const handleMouseEnter = (e, value) => {
    setAnchorEl(e.currentTarget);
    setPopperContent(value);
  };

  const handleMouseLeave = (e) => {
    if (e.relatedTarget?.id !== "popper_content") {
      setAnchorEl(null);
      setPopperContent(null);
    }
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
    setPopperContent(null);
  };

  const productImage = (value) => {
    switch (value) {
      case "H PCV Taxi":
        return <img src={HPCVTaxi} alt="H PCV Taxi" />;
      case "H PVT Car":
        return <img src={HPrivatecar} alt="H PVT Car" />;
      case "Two WH":
        return <img src={Scooter} alt="Two Wh" />;
      case "PVT Car":
        return <img src={PrivateCar} alt="PVT Car" />;
      case "E PCV Taxi":
        return <img src={EPCVTaxi} alt="E PCV Taxi" />;
      case "E GCV Truck":
        return <img src={EGCVTruck} alt="E GCV Truck" />;
      case "E GCV Auto":
        return <img src={EGCVAuto} alt="E GCV Auto" />;
      case "PCV Auto":
        return <img src={PCVAuto} alt="PCV Auto" />;
      case "GCV Truck":
        return <img src={GCVTruck} alt="GCV Truck" />;
      case "Misc Vehicles":
        return <img src={MiscVehicles} alt="Misc Vehicles" />;
      case "E PVT Car":
        return <img src={EPrivateCar} alt="E PVT Car" />;
      case "PCV Taxi":
        return <img src={PCVTaxi} alt="PCV Taxi" />;
      case "PCV BusMxi":
        return <img src={PCVBusMixi} alt="PCV BusMxi" />;
      case "Q E PVT Car":
        return <img src={QEPrivateCar} alt="Q E PVT Car" />;
      case "Q PVT Car":
        return <img src={QPrivateCar} alt="Q PVT Car" />;
      case "E PCV Auto":
        return <img src={EPCVAuto} alt="E PCV Auto" />;
      case "GCV Auto":
        return <img src={GCVAuto} alt="GCV Auto" />;
      case "E PCV BusMxi":
        return <img src={EPCVBusMixi} alt="E PCV BusMxi" />;
      case "E Two WH":
        return <img src={E2Wheeler} alt="E Two WH" />;
      case "CV Two WH":
        return <img src={CV2Wheeler} alt="CV Two WH" />;
      case "CV E Two WH":
        return <img src={ECV2Wheeler} alt="CV E Two WH" />;
      default:
        return null;
    }
  };

  const insuranceCompanyIcons = {
    "United India Insurance": Uii,
    "New India Assurance": Nia,
    "Oriental Insurance": Oic,
    "National Insurance Company": Nic,
  };

  const endPageCount = page + 1;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const customLocaleText = {
    noResultsOverlayLabel: "No Data Found",
  };

  const columns1 = [
    {
      field: "Profile",
      headerName: "Image",
      minWidth: 40,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Avatar
          src={params.value !== "" ? params.value : null}
          style={{
            width: "40px",
            height: "40px",
          }}
          alt="Image"
        />
      ),
    },
    {
      field: "userName",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
    },
    {
      field: "Mobile",
      headerName: "Mobile No.",
      headerClassName: "super-app-theme--header",
      minWidth: 135,
      valueGetter: (params) => {
        return `+${params.value}`;
      },
    },
    {
      field: "Email",
      headerName: "Mail Id",
      headerClassName: "super-app-theme--header",
      minWidth: 220,
      renderCell: (params) => (
        <p
          style={{
            color: "#5F5BFF",
          }}
        >
          {params.value}
        </p>
      ),
    },
    {
      field: "TxnDate",
      headerName: "Txn Date",
      headerClassName: "super-app-theme--header",
      minWidth: 110,
    },
    {
      field: "lob",
      headerName: "LOB",
      headerClassName: "super-app-theme--header",
      minWidth: 90,
      renderCell: ({ value }) => {
        if (value == "MOTOR") {
          return "Motor";
        }
      },
    },

    {
      field: "InsuranceCompany",
      headerName: "Insurance Companies",
      headerClassName: "super-app-theme--header",
      minWidth: 260,
      renderCell: ({ value }) => {
        const icon = insuranceCompanyIcons[value];
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            {icon && (
              <img
                src={icon}
                alt={value}
                style={{ width: "27px", height: "27px", marginRight: "10px" }}
              />
            )}
            {value}
          </span>
        );
      },
    },

    {
      field: "Product",
      headerName: "Product",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      renderCell: ({ value }) => {
        const productIcons = productImage(value);
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {productIcons && (
              <img
                src={productIcons.props.src}
                style={{
                  width: "32px",
                  height: "28px",
                  marginRight: "10px",
                }}
                alt={value}
              />
            )}
            {value}
          </span>
        );
      },
    },
    {
      field: "Policy",
      headerName: "Policy",
      headerClassName: "super-app-theme--header",
      minWidth: 220,
    },

    {
      field: "VehicleDetails",
      headerName: "Vehicle Details",
      headerClassName: "super-app-theme--header",
      width: 400,
      renderCell: ({ value }) => (
        <div
          style={{ whiteSpace: "pre-line" }}
          onMouseEnter={(e) => {
            const popperContent = `${value.calculation || ""}${
              value.calculation && value.result ? ", " : ""
            }${value.result || ""}`;
            handleMouseEnter(e, popperContent);
          }}
          onMouseLeave={handleMouseLeave}
        >
          {value?.calculation ? value.calculation : "-"}
          {value?.calculation && value?.result && ", "}
          {value?.result ? (
            <span style={{ color: "#C679F6", fontWeight: 600 }}>
              {value?.result.replace("(₹)", "")}
            </span>
          ) : (
            ""
          )}
        </div>
      ),
    },

    {
      field: "OwnDamagePremium",
      headerName: "Own Damage Premium",
      headerClassName: "super-app-theme--header",
      minWidth: 420,
      renderCell: ({ value }) => (
        <div
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: 3,
            whiteSpace: "pre-line",
          }}
          onMouseEnter={(e) => {
            const popperContent =
              value?.calculation || value?.result
                ? `${value?.calculation || ""}${
                    value?.calculation && value?.result ? ", " : ""
                  }${value?.result || ""}`
                : "";
            handleMouseEnter(e, popperContent);
          }}
          onMouseLeave={handleMouseLeave}
        >
          {value?.calculation ? value.calculation : "-"}
          {value?.calculation && value?.result && ", "}
          {value?.result ? (
            <span style={{ color: "#C679F6", fontWeight: 600 }}>
              {value?.result.replace("(₹)", "")}
            </span>
          ) : (
            ""
          )}
        </div>
      ),
    },

    {
      field: "LiabilityPremium",
      headerName: "Liability Premium",
      headerClassName: "super-app-theme--header",
      minWidth: 420,
      renderCell: ({ value }) => (
        <div
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: 3,
            whiteSpace: "pre-line",
          }}
          onMouseEnter={(e) => {
            const popperContent = `${value.calculation || ""}${
              value.calculation && value.result ? ", " : ""
            }${value.result || ""}`;
            handleMouseEnter(e, popperContent);
          }}
          onMouseLeave={handleMouseLeave}
        >
          {value?.calculation ? value.calculation : "-"}
          {value?.calculation && value?.result && ", "}
          {value?.result ? (
            <span style={{ color: "#C679F6", fontWeight: 600 }}>
              {value?.result.replace("(₹)", "")}
            </span>
          ) : (
            ""
          )}
        </div>
      ),
    },

    {
      field: "NetPremiumPayable",
      headerClassName: "super-app-theme--header",
      headerName: "Net Premium Payable",
      minWidth: 210,
      renderCell: ({ value }) => {
        let premium =
          value?.result == "" ? "-" : value?.result.replace("(₹)", "");
        return (
          <div>
            <span style={{ color: "#C679F6", fontWeight: 600 }}>
              {`${premium}`}
            </span>
          </div>
        );
      },
    },
  ];

  const columns2 = [
    {
      field: "Profile",
      headerName: "Image",
      minWidth: 69,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Avatar
          src={params.value !== "" ? params.value : null}
          style={{
            width: "40px",
            height: "40px",
          }}
          alt="Image"
        />
      ),
    },
    {
      field: "userName",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      minWidth: 170,
    },
    {
      field: "Mobile",
      headerName: "Mobile No.",
      headerClassName: "super-app-theme--header",
      minWidth: 135,
      valueFormatter: (params) => {
        return `+${params.value}`;
      },
    },
    {
      field: "Email",
      headerName: "Mail Id",
      headerClassName: "super-app-theme--header",
      minWidth: 220,
      renderCell: (params) => (
        <p
          style={{
            color: "#5F5BFF",
          }}
        >
          {params.value}
        </p>
      ),
    },
    {
      field: "TxnDate",
      headerName: "Txn Date",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
    },
    {
      field: "lob",
      headerName: "LOB",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      renderCell: ({ value }) => {
        if (value == "FIRE") {
          return "Fire";
        }
      },
    },
    {
      field: "InsuranceCompany",
      headerName: "Insurance Companies",
      headerClassName: "super-app-theme--header",
      minWidth: 260,
      renderCell: ({ value }) => {
        const icon = insuranceCompanyIcons[value];

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {icon && (
              <img
                src={icon}
                style={{
                  width: "27px",
                  height: "27px",
                  marginRight: "10px",
                }}
                alt={value}
              />
            )}
            {value}
          </div>
        );
      },
    },

    {
      field: "Policy",
      headerName: "Policy",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      renderCell: ({ value }) => {
        const words = value.split(" ");
        // console.log(words, "words");
        if (words.length > 1) {
          words[words.length - 1] = <strong>{words[words.length - 1]}</strong>;
        }
        return (
          <div
            style={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: 3,
              whiteSpace: "pre-line",
            }}
          >
            <span>
              {words.map((word, index) => (
                <React.Fragment key={index}>
                  {index > 0 && " "}
                  {word}
                </React.Fragment>
              ))}
            </span>
          </div>
        );
      },
    },

    {
      field: "FireDescription",
      headerName: "Fire Description",
      headerClassName: "super-app-theme--header",
      minWidth: 420,

      renderCell: ({ value }) => (
        <div
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: 3,
            whiteSpace: "pre-line",
          }}
          onMouseEnter={(e) => {
            const popperContent = `${value.calculation || ""}${
              value.calculation && value.result ? ", " : ""
            }${value.result || ""}`;
            handleMouseEnter(e, popperContent);
          }}
          onMouseLeave={handleMouseLeave}
        >
          {value?.calculation ? value?.calculation : "-"}
        </div>
      ),
    },

    {
      field: "NetPremiumPayable",
      headerName: "Net Premium Payable",
      headerClassName: "super-app-theme--header",
      minWidth: 220,

      renderCell: ({ value }) => {
        let premium =
          value?.result == "" ? "-" : value?.result.replace("(₹)", "");
        return (
          <div>
            <span style={{ color: "#C679F6", fontWeight: 600 }}>{premium}</span>
          </div>
        );
      },
    },
  ];

  const columns3 = [
    {
      field: "Profile",
      headerName: "Image",
      minWidth: 69,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Avatar
          src={params.value !== "" ? params.value : null}
          style={{
            width: "40px",
            height: "40px",
          }}
          alt="Image"
        />
      ),
    },
    {
      field: "userName",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      minWidth: 170,
    },
    {
      field: "Mobile",
      headerName: "Mobile No.",
      headerClassName: "super-app-theme--header",
      minWidth: 135,
      valueFormatter: (params) => {
        return `+${params.value}`;
      },
    },
    {
      field: "Email",
      headerName: "Mail Id",
      headerClassName: "super-app-theme--header",
      minWidth: 220,
      renderCell: (params) => (
        <p
          style={{
            color: "#5F5BFF",
          }}
        >
          {params.value}
        </p>
      ),
    },
    {
      field: "TxnDate",
      headerName: "Txn Date",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
    },
    {
      field: "lob",
      headerName: "LOB",
      headerClassName: "super-app-theme--header",
      minWidth: 110,
      renderCell: ({ value }) => {
        if (value == "HEALTH") {
          return "Health";
        }
      },
    },
    {
      field: "InsuranceCompany",
      headerName: "Insurance Companies",
      headerClassName: "super-app-theme--header",
      minWidth: 270,
      renderCell: ({ value }) => {
        const icon = insuranceCompanyIcons[value];
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {icon && (
              <img
                src={icon}
                style={{
                  width: "27px",
                  height: "27px",
                  marginRight: "10px",
                }}
                alt={value}
              />
            )}
            {value}
          </div>
        );
      },
    },

    {
      field: "Policy",
      headerName: "Policy",
      headerClassName: "super-app-theme--header",
      minWidth: 250,
      renderCell: ({ value }) => {
        const words = value.split(" ");
        // console.log(words, "words");
        if (words.length > 1) {
          words[words.length - 1] = <strong>{words[words.length - 1]}</strong>;
        }
        return (
          <div
            style={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: 3,
              whiteSpace: "pre-line",
            }}
          >
            <span>
              {words.map((word, index) => (
                <React.Fragment key={index}>
                  {index > 0 && " "}
                  {word}
                </React.Fragment>
              ))}
            </span>
          </div>
        );
      },
    },

    {
      field: "HealthBasicDetails",
      headerName: "Health Basic Details",
      headerClassName: "super-app-theme--header",
      minWidth: 280,

      renderCell: ({ value }) => (
        <div
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: 3,
            whiteSpace: "pre-line",
          }}
          onMouseEnter={(e) => {
            const popperContent = `${value.calculation || ""}${
              value.calculation && value.result ? ", " : ""
            }${value.result || ""}`;
            handleMouseEnter(e, popperContent);
          }}
          onMouseLeave={handleMouseLeave}
        >
          {value?.calculation ? value.calculation : "-"}
          {value?.calculation && value?.result && ", "}
          {value?.result ? (
            <span style={{ color: "#C679F6", fontWeight: 600 }}>
              {value?.result.replace("(₹)", "")}
            </span>
          ) : (
            ""
          )}
        </div>
      ),
    },

    {
      field: "HealthDescription",
      headerName: "Health Description",
      headerClassName: "super-app-theme--header",
      minWidth: 360,

      renderCell: ({ value }) => (
        <div
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: 3,
            whiteSpace: "pre-line",
          }}
          onMouseEnter={(e) => {
            const popperContent = `${value.calculation || ""}${
              value.calculation && value.result ? ", " : ""
            }${value.result || ""}`;
            handleMouseEnter(e, popperContent);
          }}
          onMouseLeave={handleMouseLeave}
        >
          <span>
            {value?.calculation ? value.calculation : "-"}
            {value?.calculation && value?.result && ", "}
            {value?.result ? (
              <span style={{ color: "#C679F6", fontWeight: 600 }}>
                {value?.result.replace("(₹)", "")}
              </span>
            ) : (
              ""
            )}
          </span>
        </div>
      ),
    },

    {
      field: "AllPremiumPayable",
      headerName: "All Premium Details",
      headerClassName: "super-app-theme--header",
      minWidth: 220,

      renderCell: ({ value }) => {
        let premium =
          value?.result == "" ? "-" : value?.result.replace("(₹)", "");
        return (
          <div>
            <span style={{ color: "#C679F6", fontWeight: 600 }}>{premium}</span>
          </div>
        );
      },
    },
  ];

  const columns =
    field == "MOTOR"
      ? columns1
      : field == "FIRE"
      ? columns2
      : field == "HEALTH"
      ? columns3
      : "";

  const isPlanDataNotEmpty = typeof planData === "string" ? [] : planData;
  function CustomFooter() {
    const [selectedPage, setSelectedPage] = useState(1);
    const handlePage = (event, value) => {
      setSelectedPage(value);
      setPage(value - 1);
    };

    const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));
    return (
      <Grid
        container
        style={{
          justifyContent: "space-between",
          paddingTop: "15px",
          paddingBottom: "15px",
          paddingLeft: isSmallerScreen ? "0px" : "15px",
        }}
      >
        <Grid item>
          {isSmallerScreen ? null : (
            <Typography
              sx={{
                color: "#C679F6",
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontSize: "14px",
                lineHeight: "30px",
              }}
            >
              Showing {resultLimit * page + 1} to {resultLimit * endPageCount}{" "}
              of {planCount}
            </Typography>
          )}
        </Grid>
        <Grid item>
          <Pagination
            count={Math.ceil(planCount / resultLimit)}
            shape="rounded"
            color="primary"
            page={page + 1}
            onChange={handlePage}
            boundaryCount={1}
            siblingCount={isMobile ? -1 : 1}
            renderItem={(item) => (
              <StyledPaginationItem
                item={item}
                {...item}
                sx={{
                  backgroundColor: item.selected ? "red" : "white",
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    );
  }
  if (isLoading == true) {
    return (
      <Skeleton
        sx={{ width: "100%", minHeight: "65vh" }}
        animation="wave"
        variant="rectangular"
      />
    );
  }
  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        "& .super-app-theme--header": {
          backgroundColor: "#FCF8FF",
          lineHeight: 1.5,
          color: "black",
          fontFamily: "Open Sans",
          fontSize: "15px",
          fontWeight: 600,
          fontStyle: "normal",
        },
      }}
    >
      <div style={{ height: "65vh", width: "100%" }}>
        <DataGrid
          rows={isPlanDataNotEmpty}
          rowHeight={74}
          columns={columns}
          pagination
          paginationMode="server"
          pageSize={resultLimit}
          onPageChange={handlePageChange}
          rowCount={10}
          components={{
            Footer: CustomFooter,
            // toolbar: CustomToolbar,
          }}
          sx={{
            fontFamily: "Open Sans",
            fontSize: "12.5px",
            color: "black",
            fontWeight: 400,

            [`& .${gridClasses.cell}`]: {
              py: 1.5,
            },
          }}
          localeText={customLocaleText}
        />
      </div>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onMouseLeave={handlePopperClose}
        placement="bottom-end"
        positionFixed={true}
      >
        <Box
          id={"popper_content"}
          sx={{
            padding: "8px",
            alignItems: "center",
            width: "180px",
            background: "white",
            lineHeight: "18px",
            fontFamily: "Open Sans",
            fontSize: "12px",
            color: "#5C5C5C",
            fontWeight: 400,
            borderRadius: "8px",
            boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          {popperContent}
        </Box>
      </Popper>
    </Paper>
  );
}
