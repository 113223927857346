import {
  Box,
  Dialog,
  Divider,
  Input,
  InputLabel,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Upload from "../assets/images/Upload.svg";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { deleteSignatureApi, updateUserProfileApi } from "../api/adminApi";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";

function EditPersonInfo(props) {
  const {
    dialogOpen,
    onClose,
    userData,
    editedData,
    handleValueChange,
    handleDateChange,
    handleSaveClick,
    rowId,
    fetchData,
  } = props;

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);

  const validateName = () => {
    // Basic validation for Name (non-empty)
    if (!editedData.Name.trim()) {
      setNameError(true);
      return false;
    }
    setNameError(false);
    return true;
  };

  const validateEmail = () => {
    // Basic validation for Email (non-empty and simple format check)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!editedData.Email.trim() || !emailRegex.test(editedData.Email)) {
      setEmailError(true);
      return false;
    }
    setEmailError(false);
    return true;
  };

  const validateMobile = () => {
    const mobileRegex = /^[6-9]\d{9}$/;
    const data = editedData.Mobile;
    if (!data || !mobileRegex.test(data)) {
      setMobileError(true);
      return false;
    }
    setMobileError(false);
    return true;
  };

  const handleSave = () => {
    const isNameValid = validateName();
    const isEmailValid = validateEmail();
    const isMobileValid = validateMobile();

    if (isNameValid && isEmailValid && isMobileValid) {
      handleSaveClick();
    }
  };

  const handleDeleteSignature = async (event) => {
    try {
      const payload = { Signature: "signature" };

      const res = await deleteSignatureApi(rowId, payload);
      if (res.statusCode === 200) {
        fetchData();
      } else {
      }
    } catch (error) {
      console.error("Error deleting signature:", error);
    }
  };

  const handleSignatureChange = async (event) => {
    const signatureFile = event.target.files[0];
    if (signatureFile) {
      try {
        const formData = new FormData();

        formData.append("Signature", signatureFile);
        const res = await updateUserProfileApi(rowId, formData);
        if (res.statusCode === 200) {
          fetchData();
          event.target.value = null;
        } else {
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const datePickerStyle = {
    width: "295px",

    fontSize: "4px",
    padding: "0px 12px ",
    ".MuiInputBase-root": {
      height: "36px",
    },
    ".MuiSvgIcon-root": {
      width: "20px",
      height: "20px",
    },
  };

  const upload = {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  };

  const textbox = {
    display: "flex",
    padding: "0px 12px",
    borderRadius: "2px",
    "& .MuiInputBase-input": {
      height: "2.5px",
      fontSize: "14px",

      fontStyle: "normal",
      fontWeight: "400",
    },
  };

  let header = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#565656",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    padding: "6px 12px 6px 12px",
  };

  const styles = {
    borderStyle: "solid 1.5px",
    borderColor: "#ECEBEB",
    width: "auto",
  };
  const headertext = {
    color: "#464646",
    fontFamily: "Open Sans",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    padding: "10px",
  };

  const icon = {
    fontSize: "medium",
  };

  const savebutton = {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#C679F6",
    color: "white",
    textTransform: "capitalize",
    padding: "6px 12px",
    margin: "12px",
    width: "295px",
  };

  return (
    <div>
      <Dialog open={dialogOpen}>
        <Box
          sx={{
            width: "320px",
            height: "auto",
            backgroundColor: "white",
            boxSizing: "border-box",
            borderRadius: "8px",
          }}
        >
          <Typography style={header}>
            Personal Information
            <IconButton onClick={onClose}>
              <ClearIcon style={icon} />
            </IconButton>
          </Typography>
          <Divider style={styles} />

          <Typography sx={headertext}>Name</Typography>
          <TextField
            size="medium"
            sx={textbox}
            variant="outlined"
            value={editedData.Name}
            onChange={handleValueChange("Name")}
            error={nameError}
            helperText={nameError ? "Name cannot be empyty" : ""}
          />

          <Typography sx={headertext}> Email Id</Typography>
          <TextField
            size="medium"
            sx={textbox}
            variant="outlined"
            value={editedData.Email}
            onChange={handleValueChange("Email")}
            error={emailError}
            helperText={emailError ? "Enter a valid Email Address" : ""}
          />

          <Typography sx={{ ...headertext, padding: "12px 12px 8px 12px" }}>
            Date of Birth
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                ...datePickerStyle,
              }}
              format="DD-MM-YYYY"
              value={
                editedData.DateOfBirth
                  ? dayjs(editedData.DateOfBirth, { format: "YYYY-MM-DD" })
                  : null
              }
              onChange={handleDateChange}
            />
          </LocalizationProvider>

          <Typography sx={headertext}> Mobile Number </Typography>
          <TextField
            size="medium"
            sx={textbox}
            variant="outlined"
            value={editedData.Mobile}
            onChange={handleValueChange("Mobile")}
            error={mobileError}
            helperText={
              mobileError ? "Enter a valid 10-digit number starts with 6-9" : ""
            }
          />
          <Typography
            style={{
              ...headertext,
              paddingLeft: "12px",
              paddingBottom: "0px",
            }}
          >
            Signature
          </Typography>
          <Box
            style={{
              display: "flex",
              border: "1px solid",
              borderColor: "darkgray",
              padding: "12px",
              height: "auto",
              width: "268px",
              margin: "12px",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "4px",
              position: "relative",
            }}
          >
            {userData?.Signature && (
              <Box>
                <img
                  src={userData?.Signature}
                  alt=""
                  style={{
                    border: "1px solid",
                    borderColor: "darkgray",
                    borderRadius: "4px",
                    height: "46px",
                    width: "120px",
                    objectFit: "contain",
                  }}
                />
                <IconButton
                  style={{
                    position: "absolute",
                    top: 6,
                    right: 138,
                    bottom: 58,
                  }}
                  onClick={handleDeleteSignature}
                >
                  <CancelSharpIcon
                    style={{
                      fill: "#bdbdbd",
                      height: "18px",
                    }}
                  />
                </IconButton>
              </Box>
            )}

            <div style={{ flex: 1 }} />
            <InputLabel htmlFor="signatureInput" style={{ upload }}>
              <Box
                style={{
                  border: "1px solid",
                  padding: "4px",
                  borderRadius: "4px",
                  border: "none",
                  background: "rgba(198, 121, 246, 0.10)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={Upload}
                  style={{
                    width: "25px",
                    height: "27px",
                    marginRight: "2px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#C679F6",
                  }}
                >
                  Upload
                </Typography>
              </Box>
            </InputLabel>
            <Input
              type="file"
              id="signatureInput"
              sx={{ display: "none" }}
              onChange={(event) => {
                handleSignatureChange(event);
              }}
            />
          </Box>

          <Typography
            style={{ ...headertext, paddingTop: "0px", paddingBottom: "10px" }}
          >
            Address
          </Typography>
          <TextField
            size="smnall"
            style={{ ...textbox, width: "292px" }}
            multiline
            minRows={1}
            value={editedData.Address}
            onChange={handleValueChange("Address")}
          />
          <Button sx={savebutton} onClick={handleSave} variant="contained">
            Save
          </Button>
        </Box>
      </Dialog>
    </div>
  );
}

export default EditPersonInfo;
