import React from "react";
import { Box, Divider, Grid, Typography, Avatar } from "@mui/material";
import Profile from "../assets/images/Profile.svg";
import Mail from "../assets/images/Mail.svg";
import Phone from "../assets/images/Phone.svg";
import DOB from "../assets/images/Dob.svg";
import Signature from "../assets/images/Sign.svg";
import Address from "../assets/images/Address.svg";

function PersonalInfo({ userData }) {
  const styles = {
    borderStyle: "solid 1px",
    borderColor: "#F5F5F5",
    borderBottomWidth: "thin",
  }; //border

  const images = {
    width: "26px",
    height: "26px",
  }; //Avatar

  const text = {
    display: "flex",
    alignItems: "center",
    padding: "8px ",
  }; //header

  const label = {
    color: "#565656",
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "18px",
    letterSpacing: "0.1px",
    margin: "0 12px",
    width: "150px",
  }; //headertext

  const json = {
    color: "#000000",
    fontFamily: "Open Sans",
    fontSize: "11px",
    fontWeight: 500,
    lineHeight: "18px",
    letterSpacing: "0.1px",
    flexWrap: "wrap",
  }; //values

  const formattedDate = (dateString) => {
    if (!dateString) {
      return "";
    }

    const inputDate = new Date(dateString);
    if (isNaN(inputDate.getTime())) {
      return "";
    }
    const day = inputDate.getUTCDate().toString().padStart(2, "0");
    const month = (inputDate.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = inputDate.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const originalMobileNumber = String(userData?.Mobile);

  const formattedMobileNumber = originalMobileNumber.slice(2);

  return (
    <Box
      sx={{
        border: "solid 1px",
        borderColor: "#ECEBEB",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        // padding: '18px',
        width: "530px",
        height: "auto",

        //box
      }}
    >
      <Box>
        <Grid container sx={{ display: "flex", flexDirection: "column" }}>
          <Grid style={text}>
            <img style={images} src={Profile} alt="Profile" />
            <Typography style={label}> Name </Typography>
            <Typography sx={json}> {userData?.Name} </Typography>
          </Grid>
          <Divider style={styles} />

          <Grid style={text}>
            <img style={images} src={Mail} alt="Mail" />
            <Typography style={label}> Email Id </Typography>
            <Typography sx={json} style={{ color: "blue" }}>
              {userData?.Email}
            </Typography>
          </Grid>
          <Divider style={styles} />

          <Grid style={text}>
            <img style={images} src={Phone} alt="Phone" />
            <Typography style={label}> Mobile Number </Typography>
            <Typography sx={json}>{formattedMobileNumber}</Typography>
          </Grid>
          <Divider style={styles} />

          <Grid style={text}>
            <img style={images} src={DOB} alt="DOB" />
            <Typography style={label}> Date of Birth </Typography>
            <Typography sx={json}>
              {formattedDate(userData?.DateOfBirth)}
            </Typography>
          </Grid>
          <Divider style={styles} />

          <Grid style={text}>
            <img style={images} src={Signature} alt="Signature" />
            <Typography style={label}>Signature</Typography>
            <Typography style={json}>
              {userData?.Signature !== "" ? (
                <img
                  src={userData?.Signature}
                  style={{
                    height: "40px",
                    width: "90px",
                    objectFit: "contain",
                  }}
                  alt="Image"
                />
              ) : (
                ""
              )}
            </Typography>{" "}
          </Grid>
          <Divider style={styles} />

          <Grid style={text}>
            <img style={images} src={Address} alt="Address" />
            <Typography style={label}> Address </Typography>
            <Typography
              style={{
                ...json,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                WebkitLineClamp: 3,
                whiteSpace: "pre-line",
              }}
            >
              {userData?.Address}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
export default PersonalInfo;
