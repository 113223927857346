import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Input, InputLabel } from "@mui/material";
import { Avatar } from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PersonalInfo from "./personalInfo";
import AddPlan from "./addPlan";
import LatestPlan from "./latestPlan";
import Pencil from "../assets/images/Pencil.svg";
import {
  addPlanProfileApi,
  updateUserProfileApi,
  userProfileApi,
} from "../api/adminApi";
import EditPersonInfo from "./dialogBox";
import AddPlanDialog from "./addPlanDialog";
import dayjs from "dayjs";

export default function UserProfile() {
  const params = new URLSearchParams(location.search);
  const rowId = params.get("userId");
  const [value, setValue] = useState("1");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [userData, setUserData] = useState(null);

  const fetchData = async () => {
    try {
      const apiData = await userProfileApi(rowId);
      setUserData(apiData[0]);
    } catch (error) {
      console.error("Error fetching user profile data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [rowId]);

  const [selectPlan, setSelectPlan] = useState("Trial");
  const [selectDate, setSelectDate] = useState("");
  const planAdd = async () => {
    const data = {
      planname: selectPlan,
      startDate: selectDate,
    };

    const res = await addPlanProfileApi(rowId, data);
    if (res.statusCode === 200) {
      fetchData();
      setOpenDialog(false);
    } else {
      console.log(res.error);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const formData = new FormData();

        formData.append("Profile", file);

        const res = await updateUserProfileApi(rowId, formData);
        if (res.statusCode === 200) {
          // console.log("Image uploaded successfully");
          fetchData();
        } else {
          // console.error("Fail to upload image");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const [editedData, setEditedData] = useState({
    Name: userData?.Name || "",
    Email: userData?.Email || "",
    Mobile: userData?.Mobile || "",
    Address: userData?.Address || "",
  });

  const handleValueChange = (field) => (event) => {
    setEditedData((prevData) => ({
      ...prevData,
      [field]: event.target.value,
    }));
  };

  const handleDateChange = (value) => {
    setEditedData((prevData) => ({
      ...prevData,
      DateOfBirth: value ? dayjs(value).format("YYYY-MM-DD") : null,
    }));
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
    setEditedData({
      Name: userData?.Name || "",
      Email: userData?.Email || "",
      Mobile: String(userData?.Mobile).slice(2) || "",
      Address: userData?.Address || "",
      DateOfBirth: userData?.DateOfBirth || "",
      Signature: editedData.Signature || "",
    });
  };
  const handleSaveClick = async () => {
    setDialogOpen(false);

    try {
      const userId = rowId;
      const payload = {
        username: editedData.Name,
        email: editedData.Email,
        Mobile: 91 + editedData.Mobile,
        Address: editedData.Address,
        DateOfBirth: editedData.DateOfBirth,
        Signature: editedData.Signature,
      };

      const res = await updateUserProfileApi(userId, payload);

      if (res.statusCode === 200) {
        fetchData();
      } else {
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const commonTextStyles = {
    fontFamily: "Open Sans",
    fontWeight: 600,
    textTransform: "capitalize",
  };

  const hrStyle = {
    borderWidth: 0,
    borderStyle: "solid",
    borderColor: "#ECEBEB",
    borderBottomWidth: "thin",
  };
  const addplan = {
    textTransform: "capitalize",
    fontFamily: "Open Sans",
    border: "solid 1.5px",
    fontSize: "0.85rem",
    lineHeight: "1.60",
    letterSpacing: "0.00356em",
    padding: "6px 12px",
  };
  const editperson = {
    textTransform: "capitalize",
    backgroundColor: "#C679F6",
    color: "white",
    margin: "12px",
    fontFamily: "Open Sans",
    fontSize: "0.85rem",
    lineHeight: "1.60",
    letterSpacing: "0.00356em",
    padding: "6px 12px",
  };

  return (
    <>
      <Grid sx={{ p: "25px" }}>
        <Grid>
          <Grid sx={{ backgroundColor: "white" }}>
            <Typography
              sx={{
                ...commonTextStyles,
                color: "#565656",
                fontSize: "14px",
                p: "12px",
              }}
            >
              User Profile
            </Typography>
          </Grid>

          <Grid>
            <hr style={hrStyle} />
          </Grid>

          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "white",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
                ...commonTextStyles,
                fontSize: "16px",
                p: "12px",
              }}
            >
              <Avatar
                src={value !== "" ? userData?.Profile : null}
                style={{ width: "60px", height: "60px" }}
                alt="Image"
              />
              <InputLabel
                htmlFor="img"
                style={{
                  position: "absolute",
                  width: "25px",
                  height: "25px",
                  objectFit: "cover",
                  marginLeft: "47px",
                  marginTop: "40px",
                  color: "#C679F6",
                  fontSize: "small",
                  cursor: "pointer",
                }}
                alt="Edit"
                // onClick={handleUpload}
              >
                <img src={Pencil} alt="Pencil" />
              </InputLabel>
              <Input
                type="file"
                id="img"
                sx={{ display: "none" }}
                onChange={handleFileChange}
              />

              <Typography
                sx={{
                  margin: "12px",
                  ...commonTextStyles,
                  fontWeight: 600,
                  color: "#565656",
                }}
              >
                {userData?.Name}
              </Typography>
            </Grid>

            <Grid>
              <Button
                sx={addplan}
                color="primary"
                variant="outlined"
                startIcon={<AddCircleRoundedIcon />}
                onClick={handleDialogOpen}
              >
                Add plan
              </Button>

              <Button
                sx={editperson}
                variant="contained"
                startIcon={<ModeEditIcon />}
                onClick={handleOpenDialog}
              >
                Edit Profile
              </Button>
            </Grid>
          </Grid>

          <Grid>
            <hr style={hrStyle} />
          </Grid>

          <Box
            sx={{
              width: "100%",
              typography: "body1",
              backgroundColor: "white",
            }}
          >
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  backgroundColor: "white",
                }}
              >
                <TabList onChange={handleChange}>
                  <Tab
                    style={{
                      ...commonTextStyles,
                      fontWeight: 600,
                      fontSize: "14px",
                    }}
                    label="Personal Info"
                    value="1"
                  />
                  <Tab
                    style={{
                      ...commonTextStyles,
                      fontWeight: 600,
                      fontSize: "14px",
                    }}
                    label="Plan Details"
                    value="2"
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <PersonalInfo userData={userData} />
              </TabPanel>
              <TabPanel value="2">
                <Grid sx={{ display: "flex" }}>
                  {userData?.PreviousPlanStatus && (
                    <AddPlan fetchData={fetchData} userData={userData} />
                  )}
                  <Grid sx={{ marginLeft: "20px" }}>
                    {userData?.LastPlanStatus && (
                      <LatestPlan fetchData={fetchData} userData={userData} />
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </Grid>

      <EditPersonInfo
        userData={userData}
        dialogOpen={dialogOpen}
        onClose={handleCloseDialog}
        editedData={editedData}
        handleValueChange={handleValueChange}
        handleDateChange={handleDateChange}
        handleSaveClick={handleSaveClick}
        rowId={rowId}
        fetchData={fetchData}
      />
      <AddPlanDialog
        openDialog={openDialog}
        onClose={handleDialogClose}
        setSelectPlan={setSelectPlan}
        selectPlan={selectPlan}
        planAdd={planAdd}
        selectDate={selectDate}
        setSelectDate={setSelectDate}
      />
    </>
  );
}
