import React, { useState } from "react";
import {
  Avatar,
  Grid,
  Paper,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import planActiveImage from "../assets/images/planActiveImage.svg";
import planExpiredImage from "../assets/images/planStatus.svg";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import SilverPlan from "../assets/images/Silver.svg";
import GoldPlan from "../assets/images/GoldImage.svg";
import PlatinumPlan from "../assets/images/PlatinumImage.svg";
import TrialPlan from "../assets/images/Trial.svg";
import { StyledPaginationItem } from "../StyledComponents/StyledComponents";
import Button from "@mui/material/Button";

export default function StickyHeadTable(props) {
  const {
    planCount,
    resultLimit,
    planData,
    page,
    handlePageChange,
    isLoading,
    setPage,
    handleView,
  } = props;

  const endPageCount = page + 1;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const customLocaleText = {
    noResultsOverlayLabel: "No Data Found",
  };
  const columns = [
    {
      field: "Profile",
      headerName: "Image",
      minWidth: 69,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Avatar
          src={params.value !== "" ? params.value : null}
          style={{
            width: "40px",
            height: "40px",
          }}
          alt="Image"
        />
      ),
    },
    {
      field: "Name",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      minWidth: 170,
    },
    {
      field: "DateOfBirth",
      headerName: "DOB",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
    },
    {
      field: "Mobile",
      headerName: "Mobile No.",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
      valueFormatter: (params) => {
        return `+${params.value}`;
      },
    },
    {
      field: "Email",
      headerName: "Mail Id",
      headerClassName: "super-app-theme--header",
      minWidth: 220,
      renderCell: (params) => (
        <span
          style={{
            color: "#5F5BFF",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "Address",
      headerName: "Address",
      headerClassName: "super-app-theme--header",
      minWidth: 370,
      renderCell: (params) => (
        <div
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: 4,
            whiteSpace: "pre-line",
          }}
        >
          {params.value !== "" ? params.value : "-"}
        </div>
      ),
    },

    {
      field: "Signature",
      headerName: "Signature",
      headerClassName: "super-app-theme--header",
      minWidth: 180,
      renderCell: (params) =>
        params.value.length > 1 ? (
          <img
            src={params.value}
            style={{
              width: "70px",
              height: "35px",
              backgroundColor: "none",
              background: "none",
              objectFit: "contain",
            }}
            alt=""
          />
        ) : (
          "-"
        ),
    },
    {
      field: "CompanyName",
      headerName: "Insurance Companies",
      headerClassName: "super-app-theme--header",
      minWidth: 210,
    },
    {
      field: "LastPlanStatus",
      headerName: "Plan Status",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
      renderCell: (params) => (
        <img
          src={params.value === "Active" ? planActiveImage : planExpiredImage}
          alt="Image"
        />
      ),
    },
    {
      field: "LastPlanName",
      headerName: "Latest Plan",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
      renderCell: (params) => {
        let imageSource;

        switch (params.value) {
          case "Silver":
            imageSource = SilverPlan;
            break;
          case "Gold":
            imageSource = GoldPlan;
            break;
          case "Platinum":
            imageSource = PlatinumPlan;
            break;
          case "nill":
            imageSource = TrialPlan;
            break;
          case "Trial":
            imageSource = TrialPlan;
            break;
          default:
            imageSource = null;
            break;
        }

        return <img src={imageSource} alt="Image" />;
      },
    },

    {
      field: "LastPlanStartDate",
      headerName: "LP - Start date",
      headerClassName: "super-app-theme--header",
      minWidth: 130,
    },

    {
      field: "LastPlanEndDate",
      headerName: "LP - End date",
      headerClassName: "super-app-theme--header",
      minWidth: 130,
    },

    {
      field: "LastPlanAmount",
      headerName: "LP - Amount paid",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      renderCell: (params) => <span>Rs.{params.value}</span>,
    },

    {
      field: "PreviousPlanName",
      headerName: "Previous Plan",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        let imageSource;

        switch (params.value) {
          case "Silver":
            imageSource = SilverPlan;
            break;
          case "Gold":
            imageSource = GoldPlan;
            break;
          case "Platinum":
            imageSource = PlatinumPlan;
            break;
          case "nill":
            imageSource = TrialPlan;
            break;
          case "Trial":
            imageSource = TrialPlan;
            break;
          default:
            imageSource = null;
            break;
        }
        if (imageSource !== null) {
          return <img src={imageSource} alt="Image" />;
        } else {
          return "-";
        }
      },

      minWidth: 160,
    },

    {
      field: "PreviousPlanStartDate",
      headerClassName: "super-app-theme--header",
      headerName: "PP - Start date",
      minWidth: 130,
      renderCell: (params) => {
        const inputDate = new Date(params.value);
        const day = inputDate.getUTCDate().toString().padStart(2, "0");
        const month = (inputDate.getUTCMonth() + 1).toString().padStart(2, "0");
        const year = inputDate.getUTCFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate == "NaN-NaN-NaN" ? "-" : formattedDate;
      },
    },
    {
      field: "PreviousPlanEndDate",
      headerClassName: "super-app-theme--header",
      headerName: "PP - End date",
      minWidth: 130,
      renderCell: (params) => {
        const inputDate = new Date(params.value);
        const day = inputDate.getUTCDate().toString().padStart(2, "0");
        const month = (inputDate.getUTCMonth() + 1).toString().padStart(2, "0");
        const year = inputDate.getUTCFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate == "NaN-NaN-NaN" ? "-" : formattedDate;
      },
    },
    {
      field: "PreviousPlanAmount",
      headerClassName: "super-app-theme--header",
      headerName: "PP - Amount paid",
      minWidth: 135,
      renderCell: (params) =>
        params.formattedValue !== undefined
          ? `Rs.${params.formattedValue}`
          : "",
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      minWidth: 90,
      renderCell: (params) => {
        const rowID = params.row._id;
        // console.log("elango", params);
        return (
          <Button
            sx={{
              textTransform: "capitalize",
              borderColor: "#C679F6",
              border: "0.002px solid #C679F6",
              fontFamily: "Open Sans",
              color: "#C679F6",
              padding: "0px 15px",
              borderRadius: "5px",
            }}
            onClick={() => handleView(rowID)}
          >
            View
          </Button>
        );
      },
    },
  ];

  const isPlanDataNotEmpty = typeof planData === "string" ? [] : planData;
  function CustomFooter() {
    const [selectedPage, setSelectedPage] = useState(1);
    const handlePage = (event, value) => {
      setSelectedPage(value);
      setPage(value - 1);
    };

    const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));
    return (
      <Grid
        container
        style={{
          justifyContent: "space-between",
          paddingTop: "15px",
          paddingBottom: "15px",
          paddingLeft: isSmallerScreen ? "0px" : "15px",
        }}
      >
        <Grid item>
          {isSmallerScreen ? null : (
            <Typography
              sx={{
                color: "#C679F6",
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontSize: "14px",
                lineHeight: "30px",
              }}
            >
              Showing {resultLimit * page + 1} to {resultLimit * endPageCount}{" "}
              of {planCount}
            </Typography>
          )}
        </Grid>
        <Grid item>
          <Pagination
            count={Math.ceil(planCount / resultLimit)}
            shape="rounded"
            color="primary"
            page={page + 1}
            onChange={handlePage}
            boundaryCount={1}
            siblingCount={isMobile ? -1 : 1}
            renderItem={(item) => (
              <StyledPaginationItem
                item={item}
                {...item}
                sx={{
                  backgroundColor: item.selected ? "red" : "white",
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    );
  }
  if (isLoading == true) {
    return (
      <Skeleton
        sx={{ width: "100%", minHeight: "65vh" }}
        animation="wave"
        variant="rectangular"
      ></Skeleton>
    );
  }
  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        "& .super-app-theme--header": {
          backgroundColor: "#FCF8FF",
          color: "black",
          lineHeight: 1.5,
          fontFamily: "Open Sans",
          fontWeight: 500,
          fontSize: "15px",
          fontStyle: "normal",
        },
      }}
    >
      <div style={{ height: "65vh", width: "100%" }}>
        <DataGrid
          rows={isPlanDataNotEmpty}
          columns={columns}
          rowHeight={61}
          pagination
          paginationMode="server"
          pageSize={resultLimit}
          onPageChange={handlePageChange}
          rowCount={10}
          components={{
            Footer: CustomFooter,
          }}
          sx={{
            fontWeight: 400,
            color: "black",
            fontFamily: "Open Sans",
            fontSize: "12.5px",

            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
          localeText={customLocaleText}
        />
      </div>
    </Paper>
  );
}
