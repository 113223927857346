import React, { useState } from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import App from "./routes/router";
import reportWebVitals from "./reportWebVitals";
import { AppContext } from "./Context/AppContext";

const theme = createTheme({
  palette: {
    primary: {
      main: "#C679F6",
    },
  },
});

function AppWrapper() {
  const [limit, setLimit] = useState("");

  const contextValue = {
    limit,
    setLimit,
  };

  return (
    <AppContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

ReactDOM.render(
  <HelmetProvider>
    <AppWrapper />
  </HelmetProvider>,

  document.getElementById("root")
);

reportWebVitals();
